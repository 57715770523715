.page {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 500px;
    padding: 0 20px;

    left: 50%;
    transform: translate(-50%, 0);

    background-color: #f7f7f7;

    overflow: auto;

    display: flex;
    flex-direction: column;

    /* 추가된 부분 */
    height: 100vh; /* 높이를 화면 전체로 설정 */
}

.button-container-main {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 새로운 button-main 스타일 */
.button-main {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 16px;
  display: inline-block;
}

.button-main:hover {
  background-color: #45a049;
}

h3 {
  text-align: center;
  color: #333;
}

/* 부모 요소 (body나 wrapper 등 최상위 요소) */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
}

.page_sp {
  flex: 1;
  width: 100%;
  max-width: 900px;
  padding: 0 20px;
  margin: 0 auto; /* 가운데 정렬 */
  background-color: #5a5a5c;
  overflow: auto;
  display: flex;
  flex-direction: column;
  user-select: none; /* 텍스트 선택 불가 */
}


.centered {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center; /* Ensure text is centered */
  gap: 20px; /* Add some space between the elements */
  margin-bottom: 5px;
}

.container {
  display: grid;
  margin-top: 5px;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  gap: 10px;
  height: auto; /* 화면 높이에서 상단과 하단 여백을 뺀 높이 */
  margin-bottom: 20px; /* 바닥면 여백을 0으로 설정합니다. */
}


.column {
    border: 1px solid #f0f0f3; /* 가이드를 위한 테두리 */
  }


.titleWrap {
    text-align: center;
    margin-top: 40px;
    font-size: 20px;
    font-weight: 700;
    color: #262626;
}

.title_top_sp {
  display: flex;
  justify-content: space-between; /* 요소들을 양 끝에 배치 */
  align-items: center; /* 수직 가운데 정렬 */
  text-align: center; /* 텍스트를 수평 가운데 정렬 */
}


/* 박스 스타일 */
.explanation-box {
  display: inline-block;
  padding: 2px 6px;
  border: 2px solid #007bff; /* 테두리 색상 */
  border-radius: 5px; /* 둥근 테두리 */
  background-color: #f8f9fa; /* 배경색 */
  text-decoration: none; /* 링크 텍스트 밑줄 제거 */
  color: #007bff; /* 텍스트 색상 */
  transition: all 0.3s ease; /* 전환 효과 */
}

.explanation-box:hover {
  background-color: #007bff; /* 호버 시 배경색 변경 */
  color: #ffffff; /* 호버 시 텍스트 색상 변경 */
}

/* 내부 컨텐츠 스타일 */
.explanation-content {
  text-align: center; /* 가운데 정렬 */
}

.explanation-title {
  font-size: 2px; /* 제목 글꼴 크기 */
  font-weight: bold; /* 제목 글꼴 두껍게 */
  line-height: 1.2; /* 줄 간격 */
  margin-bottom: 5px; /* 아래 여백 */
}


.spacer {
  height: 20px; /* 원하는 높이로 설정 */
}

.titleWrap_sp {
  font-size: 30px;
  font-weight: 750;
  color: rgba(255, 250, 250, 0.959);
  margin-top: 15px;
  /* margin-left: 90px; 오른쪽으로 10px 이동 */
  margin-bottom: 15px;
}

.inputTitle_uid {
  display: flex;
  flex-direction: column; /* 요소들을 세로로 배치 */
  align-items: flex-end; /* 오른쪽 정렬 */
  font-size: 13px;
  font-weight: 650;
  color: #f8f6f6;
}

.inputTitle_uid span {
  margin-bottom: 5px; /* span 요소 사이의 간격 설정 */
}

.titleWrap_sp_2 {
    /* display: flex; */
    margin-left: auto;
    flex-grow: 1;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 30px;
    font-weight: 750;
    color: rgba(255, 250, 250, 0.959);
}

.coin_select {
    text-align: center;
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    color: rgba(255, 250, 250, 0.959);
}

.contentWrap {
    margin-top: 10px;
    flex: 1;
}

.contentWrap_sp {
    margin: auto;
    width: 80%;
    margin-top: 10px;
    flex: 1;
}

.inputTitle {
   font-size: 12px; 
   font-weight: 600;
   color: #262626;
}

.inputTitle_sp {
    display: flex;
    align-items: center;
    font-size: 13px; 
    font-weight: 650;
    color: #f8f6f6;
 }


.inputWrap {
    display: flex;
    border-radius: 8px;
    padding: 16px;
    margin-top: 8px;
    background-color: white;
    border: 1px solid #e2e0e0;
}

.leverageWrap_sp {
    margin: auto;
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 13px; 
    font-weight: 650;
    color: #f8f6f6;
 }

 .subpageWrap_sp {
    /* text-align: center; */
    margin: auto;
    width: 50%;
    display: flex;
    align-items: center;
    font-size: 13px; 
    font-weight: 650;
    color: #f8f6f6;
 }

 .lvWrap_sp {
    width: 20%;
    margin-left: auto;
    border-radius: 7px;
    padding: 5px;
    /* margin-top: 5px; */
    background-color: white;
    border: 1px solid #e2e0e0;
}

.inputWrap_sp {
    margin-left: auto;
    border-radius: 8px;
    padding: 8px;
    /* margin-top: 5px; */
    background-color: white;
    border: 1px solid #e2e0e0;
}

.input_sp:disabled {
  /* 비활성화된 입력창의 스타일 */
  background-color: #f2f2f2; /* 비활성화된 배경색 */
  color: #2b2a2a; /* 비활성화된 텍스트 색상 */
}

.inputWrap:focus-within {
    border: 1px solid #9e30f4;
}

.input {
    width: 100%;
    outline: none;
    border: none;
    height: 17px;
    font-size: 13px;
    font-weight: 400;
}

.input_sp {
    text-align: center;
    width: 100%;
    outline: none;
    border: none;
    height: 15px;
    font-size: 12px;
    font-weight: 400;
}

.input::placeholder {
    color: #dadada;
}

.input_sp::placeholder {
    color: #b8b4b4;
}

.errorMessageWrap{
    margin-top: 8px;
    color: #ef0000;
    font-size: 12px;
}

.errorMessageWrap_sp{
    margin-top: 8px;
    color: #ef0000;
    font-size: 12px;
}

.bottomButton {
    width: 100%;
    height: 48px;
    border: none;
    font-weight: 700;
    background-color: #5a9dfc;
    border-radius: 64px;
    color: white;
    margin-bottom: 16px;
    cursor: pointer;
}

.bottomButton:disabled {
    background-color: #dadada;
    color: rgb(255, 255, 255);
}

.signuplink {
    display: flex; /* 플렉스 컨테이너로 설정 */
    justify-content: space-between; /* 자식 요소들을 컨테이너 양쪽 끝으로 정렬 */
    font-size: 14px; /* 글씨 크기 조정 */
    text-align: center; /* 가운데 정렬 */
    margin-bottom: 40px;
}

.loginlink {
    font-size: 14px; /* 글씨 크기 조정 */
    text-align: center; /* 가운데 정렬 */
    margin-bottom: 40px;
}


.coin_select{
    text-align: center;
    align-items: center;
    font-size: 15px; 
    font-weight: 650;
    color: #f8f6f6;
 }

.usdt_select {
    margin-bottom: 10px;
    width: 40%;
    height: 100%;
    outline: 0;
    background: #fdfdff;
    color: #0056b3;
    border: 1px solid rgb(248, 247, 248);
    padding: 1px;
    border-radius: 7px;
    cursor: pointer;
  }

.search {
  margin: auto;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
  /* font-family: cursive, sans-serif; */
  font-size: 12px; 
  font-weight: 700;
  outline: 0;
  background: #0056b3;
  color: #f7f7fc;
  border: 1px solid rgb(250, 247, 248);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.bottomButton_sp {
  width: 100%;
  height: 40px;
  border: none;
  font-weight: 700;
  background-color: #5a9dfc;
  border-radius: 5px;
  color: white;
  margin: auto;
  margin-bottom: 10px;
  cursor: pointer;
  border: 1px solid rgb(250, 247, 248);
}

.bottomButton_sp:disabled {
  background-color: #777676;
  color: rgb(255, 255, 255);
}

.bottomButton_sp:hover {
  background-color: #0056b3; /* 마우스 호버 시 밝은 파란색 */
}

.line_top {
  border-top: 1px solid #777676;
  margin-bottom: 10px;
}

.line {
    border-top: 1px solid #777676;
    margin: 10px 0px;
  }

.vertical-line {
    border-left: 1px solid #000; /* 선의 스타일을 지정합니다. */
    height: 100%; /* 세로 길이를 화면 전체로 설정합니다. */
    position: absolute; /* 다른 요소를 덮지 않고 위치를 조정하기 위해 절대 위치 지정합니다. */
    left: 50%; /* 가운데 정렬을 위해 왼쪽으로 이동합니다. */
    transform: translateX(-50%); /* 가운데 정렬을 합니다. */
  }

.vertical-line_admin {
    border-right: 1px solid #ccc; /* 세로 줄 스타일 및 색상 설정 */
    height: 30px; /* 버튼과 동일한 높이 설정 (선택적) */
    margin: 0 5px; /* 버튼과 세로 줄 사이의 간격 설정 (선택적) */
}
  

.trend {
    align-self: center;
    width: 80%;
    display: flex;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 5px;
    outline: 0;
    background: #fdfdff;
    border: 1px solid rgb(248, 247, 248);
    padding: 1px;
    border-radius: 7px;
  }

  .trend_select {
    margin-bottom: 10px;
    margin-top: 5px;
    width: 40%;
    outline: 0;
    background: #fdfdff;
    color: #0056b3;
    border: 1px solid rgb(248, 247, 248);
    padding: 1px;
    border-radius: 7px;
    cursor: pointer;
  }

  .trend_select:disabled {
    background-color: #999797;
    color: #282929; /* 짙은 회색 */
  }


  .button_benefit {
    display: flex;
    align-self: center;
    width: 80%;
    height: 40px;
    border: none;
    font-weight: 700;
    background-color: #5a5a5c;
    border-radius: 5px;
    color: white;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    border: none;
}

.button_benefit_c {
    margin: auto;
    width: 49%;
    height: 40px;
    border: none;
    font-weight: 700;
    background-color: #5a9dfc;
    border-radius: 5px;
    color: white;
    margin-bottom: 16px;
    cursor: pointer;
    border: 1px solid rgb(250, 247, 248);
}

.button_benefit_c:disabled {
  background-color: #777676;
  color: rgb(255, 255, 255);
}

.button_benefit_c:hover {
  background-color: #0056b3; /* 마우스 호버 시 밝은 파란색 */
}



.inputTitle_sp_price {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 650;
  color: #f8f6f6;
  white-space: nowrap; /* Prevent text from wrapping */
}


.button_price_select {
  width: auto;
  height: 30px;
  border: none;
  font-weight: 700;
  background-color: #5a9dfc;
  border-radius: 5px;
  color: white;
  /* margin-bottom: 16px; */
  cursor: pointer;
  border: 1px solid rgb(250, 247, 248);
}

.button_price_select:disabled {
  background-color: #777676;
  color: rgb(255, 255, 255);
}

.button_price_select:hover {
  background-color: #0056b3; /* 마우스 호버 시 밝은 파란색 */
}

input[type="checkbox"] {
  transform: scale(1.6); /* 체크박스 크기 조정 */
  margin-left: 10px; /* Add some space between text and checkbox */
}

.select_price {
  margin-left: 20px; /* Add some space between checkbox and select */
  width: 40%;
  height: 100%;
  font-size: 15px;
  outline: 0;
  background: #fdfdff;
  color: #0056b3;
  border: 1px solid rgb(248, 247, 248);
  padding: 1px;
  border-radius: 7px;
  cursor: pointer;
}


.list_box {
  /* background-color: #3b3b3b; */
  color: white;
  height: auto; 
  max-height: 100vh;
  overflow-y: auto;
  }



.list_box_title {
  text-align: center; /* 리스트 박스 제목 가운데 정렬 */
  margin-bottom: 10px; /* 제목과 리스트 간격 조정 */
  font-size: 13px; /* 제목 글꼴 크기 조정 */
  color: white; /* 글자 색상을 흰색으로 설정 */
  background-color: black; /* 글자 박스 배경색을 검은색으로 설정 */
  padding: 5px 10px; /* 글자 박스 패딩 설정 */
  border-radius: 5px; /* 글자 박스 모서리를 둥글게 설정 */
}


.list_box li {
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 15px; /* 원하는 글꼴 크기로 조정 */
  text-align: center; /* 텍스트를 가운데 정렬 */
}

/* .list_box li::before {
    content: "$ "; /* 돈 표시 */
    /* margin-right: 5px; 돈 표시와 리스트 아이템 사이의 간격 조절 */
/* } */

.list_box li a {
    color: white;
}


.container_admin {
    text-align: center;
  }
  
  .container_admin .header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; 
  }

  .container_admin .title_admin {
    margin-bottom: 20px;
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    margin-right: 20px;
  }
  
  .container_admin .logout-button {
    position: absolute;
    right: 0;
    background-color: #4dd5ff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .container_admin .logout-button:hover {
    background-color: #3718e2;
  }

  .title_admin {
    margin-bottom: 20px;
  }
  
  .styled-input {
    width: 100px;
    padding: 8px 12px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.styled-input:focus {
  border-color: #4dd5ff;
  box-shadow: 0 0 5px rgba(77, 213, 255, 0.5);
}

  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-table th, .user-table td {
    border: 1px solid #dddddd;
    padding: 8px;
  }
  
  .user-table th {
    background-color: #f2f2f2;
  }
  
  .user-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .user-table tr:hover {
    background-color: #dddddd;
  }
  
  .action-button {
    cursor: pointer;
    margin-right: 20px; /* 원하는 여백 크기로 조정 가능 */
  }
  
  .large-button {
    padding: 10px 15px; /* 원하는 패딩 크기로 조정 가능 */
    font-size: 12px; /* 원하는 폰트 크기로 조정 가능 */
}

.vertical-line_admin {
  border-right: 3px solid #ccc; /* 세로 줄 스타일 및 색상 설정 */
  height: 40px; /* 버튼과 동일한 높이 설정 (선택적) */
  margin-right: 20px; /* 버튼과 세로 줄 사이의 간격 설정 (선택적) */
}


/* 체크박스 CSS */

.user-info {
  position: absolute;
  top: 10px; /* 조정 가능 */
  right: 10px; /* 조정 가능 */
  background-color: #fff; /* 배경색 설정 */
  padding: 10px; /* 내부 여백 설정 */
  border: 1px solid #ccc; /* 테두리 설정 */
  border-radius: 5px; /* 테두리 모서리 둥글게 설정 */
}

.container_check {
  position: relative;
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center; /* 텍스트 가운데 정렬 추가 */
}

.radio-group {
  display: flex;
  flex-direction: column;
  align-items: center; /* 아이템들을 가운데 정렬 */
}

.radio-input {
  transform: scale(1.1); /* 기본 라디오 버튼 숨김 */
}

.radio-label {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer; /* 포인터 커서 추가하여 클릭 가능한 상태로 변경 */
}

.radio-input:checked + .radio-label {
  background-color: black;
}

/* 글자 팝업 */
.popupMessage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/* 글자 팝업 */
.popupMessage_setpage {
  position: fixed;
  top: 50px;
  left: 20px;
  background-color: #868686;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 390px;
  height: 640px;
}

.popupMessage_setpage p {
  margin: 0; /* 기본 마진 제거 */
  text-align: left; /* 가로 가운데 정렬 */
  display: flex;
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
  height: 100%; /* 부모 요소의 높이를 따르도록 */
  color: white;
}

/* PrivacyTerms.css */

.confirm-button {
  background-color: #007bff; /* 파란색 배경 */
  color: white; /* 흰색 글씨 */
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.confirm-button:hover {
  background-color: #0056b3; /* 마우스 호버 시 밝은 파란색 */
}

.container_select {
  text-align: center;
}

.header_select {
  font-size: 36px; /* 더 큰 글꼴 크기 */
  font-weight: bold;
  margin-top: 100px;
  margin-bottom: 40px;
}

.button-container_select {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px; /* 버튼 사이의 간격 */
}

.button_select {
  padding: 20px 40px; /* 더 큰 패딩 */
  font-size: 24px; /* 더 큰 글꼴 크기 */
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background-color: #5a9dfc; /* 버튼 색상 변경 */
  color: white;
}

.button_select:hover {
  background-color: #8cbffc; /* 버튼 호버 색상 변경 */
}

.hidden {
  /* opacity: 0.1; 거의 보이지 않게 */
  pointer-events: none; /* 클릭 등의 이벤트 차단 */
  filter: blur(5px); /* 약간 흐릿하게 */
  user-select: none; /* 텍스트 선택 불가 */
}

/* 작은 화면(모바일 기기)에 대한 스타일 */
@media screen and (max-width: 600px) {
  .page, .page_sp {
    max-width: 90%;
    padding: 0 5%;
    margin: 0 auto;
    height: auto; /* 높이를 자동으로 설정하여 콘텐츠에 맞춤 */
}
  .column {
      margin-top: 5px; /* 칼럼 사이의 간격 설정 */
  }

  .container {
    display: grid;
    /* margin-top: 5px; */
    grid-template-columns: 1fr;
    gap: 10px;
    height: auto; /* 높이를 자동으로 설정 */
    /* margin-bottom: 20px; */
}

  .list_box_title {
    text-align: center; /* 리스트 박스 제목 가운데 정렬 */
    margin-bottom: 10px; /* 제목과 리스트 간격 조정 */
    font-size: 13px; /* 제목 글꼴 크기 조정 */
    color: white; /* 글자 색상을 흰색으로 설정 */
    background-color: black; /* 글자 박스 배경색을 검은색으로 설정 */
    padding: 5px 10px; /* 글자 박스 패딩 설정 */
    border-radius: 5px; /* 글자 박스 모서리를 둥글게 설정 */
}
  .list_box li {
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-size: 15px; /* 원하는 글꼴 크기로 조정 */
    text-align: center; /* 텍스트를 가운데 정렬 */
  }
  /* 그 외에도 다른 요소들에 대한 스타일 조정 */

  .input_sp {
    max-width: 75px; /* 모바일 화면에서의 최대 폭 */
  }

  input[type="checkbox"] {
    transform: scale(1.5); /* 체크박스 크기 조정 */
    margin-left: 5px; /* Add some space between text and checkbox */
  }

  .title_top_sp {
    display: flex;
    justify-content: space-between; /* 요소들을 양 끝에 배치 */
    /* align-items: center; 수직 가운데 정렬 */
    text-align: center; /* 텍스트를 수평 가운데 정렬 */
    margin-top: 10px;
    margin-bottom: 10px;
    /* gap: 2px; */
  }

  /* 박스 스타일 */
  .explanation-box {
    display: inline-block;
    font-size: 11px;
    padding: 2px 5px;
    border: 2px solid #007bff; /* 테두리 색상 */
    border-radius: 5px; /* 둥근 테두리 */
    background-color: #f8f9fa; /* 배경색 */
    text-decoration: none; /* 링크 텍스트 밑줄 제거 */
    color: #007bff; /* 텍스트 색상 */
    transition: all 0.3s ease; /* 전환 효과 */
    width: 90px; /* 너비 설정 */
    height: 30px; /* 높이 설정 */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .titleWrap_sp {
    font-size: 20px;
    font-weight: 750;
    color: rgba(255, 250, 250, 0.959);
    margin: 0;
    /* margin-top: 15px; */
    /* margin-left: 90px; */
    /* margin-bottom: 15px; */
  }
  
  .inputTitle_uid {
    display: flex;
    flex-direction: column; /* 요소들을 세로로 배치 */
    align-items: flex-end; /* 오른쪽 정렬 */
    font-size: 10px;
    font-weight: 650;
    color: #f8f6f6;
    width: 150px;
  }

  .select_price {
    margin-left: 20px; /* Add some space between checkbox and select */
    width: 40%;
    height: 100%;
    font-size: 13px;
    outline: 0;
    background: #fdfdff;
    color: #0056b3;
    border: 1px solid rgb(248, 247, 248);
    padding: 1px;
    border-radius: 7px;
    cursor: pointer;
  }

  .inputTitle_sp_price {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 650;
    color: #f8f6f6;
    white-space: nowrap; /* Prevent text from wrapping */
  }

  .button_price_select {
      font-size: 12px; /* 모바일 화면에서 글자 크기 조정 */
  }

  .centered {
    gap: 3px; /* Add some space between the elements */
  }

}


/* 중간 크기의 화면(태블릿 등)에 대한 스타일 */
@media screen and (min-width: 601px) and (max-width: 900px) {
  .page, .page_sp {
      max-width: 90%;
      padding: 0 15px;
      height: auto; /* 높이를 자동으로 설정 */
  }
}